import { render, staticRenderFns } from "./VotoPorPartido.vue?vue&type=template&id=84441884&scoped=true&"
import script from "./VotoPorPartido.vue?vue&type=script&lang=js&"
export * from "./VotoPorPartido.vue?vue&type=script&lang=js&"
import style0 from "./VotoPorPartido.vue?vue&type=style&index=0&id=84441884&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84441884",
  null
  
)

export default component.exports