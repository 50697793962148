<template>
  <Layout>
    <div class="row votosPorCandidaturaMovil" v-if="cargaTerminada">
      <div class="col-12 col-md-10 mx-auto">
        <router-link
          :to="`/${eleccion}/DET/VPP/${distrito}/${listaSecciones[0].seccion_id}`"
          class="btn btn-secondary btn-link text-center font-weight-bold mb-3 mx-auto d-block text-white w-75"
        >
          Sección
        </router-link>

        <h4>
          {{ titulo }} -
          <b class="primario">Detalle del Distrito</b> -
          <span class="primario">Votos por Partido Político y Candidatura Independiente </span>
        </h4>

        <p>
          <router-link :to="`/${eleccion}/ENT/VPC`" class="estado-volver">
            Baja California Sur
          </router-link> / <b>
            <span v-if="eleccion != 'A'">
              Distrito {{distrito}}. {{distrito_nombre}}
            </span>
            <span v-else>
              Municipio {{ayuntamiento.municipio_id}}. {{ayuntamiento.municipio_descripcion}}
            </span>
          </b>
        </p>

        <div class="text-justify">
          <p>
             El total de votos mostrado a nivel Entidad representa la suma del voto emitido en territorio Estatal y en el Extranjero.
          </p>

          <p class="mb-5">
            Por representación, los decimales de los porcentajes muestran sólo cuatro digitos. No obstante, al considera todos los decimales, suman 100%.
          </p>

        </div>
        

        <div class="votosEnActasContabilizadas mb-4">
          <p class="titulo">
            Votos en Actas Contabilizadas
          </p>         
        </div>

        <div class="row">
          <voto-por-partido v-for="(partido, index) in votosXPartidoXDistrito.datos_partidos"
          :key="index"
          :partido="partido"
          :esDetalle="true"
          :esXDistrito="true" />
        </div>

        <div class="resumenDeLaVotacion mb-5">
          <p class="titulo ">
            Resumen de la votación
          </p>
          <ResumenVotacion texto="Votos acumulados"
          :numero="resumenDistrito.total_votos_por_distrito_numero"
          :porcentaje="resumenDistrito.total_votos_por_distrito_porcentaje" />
          <ResumenVotacion texto="Candidaturas no registradas"
          :numero="resumenDistrito.total_votos_candidatura_noregistrada_numero"
          :porcentaje="resumenDistrito.total_votos_candidatura_noregistrada_porcentaje" />
          <ResumenVotacion texto="Votos Nulos"
          :numero="resumenDistrito.total_votos_nulos_numero"
          :porcentaje="resumenDistrito.total_votos_nulos_porcentaje" />
  
        
        </div>
        <div class="totalVotos mb-5">
          <h5 class="text-center"><b>Total de votos</b></h5>

          <h1 class="total mb-0">
            {{ resumenDistrito.total_votos_general_numero }}
          </h1>
          <p class="porcentaje mb-3">
            {{ resumenDistrito.total_votos_general_porcentaje }}%
          </p>
        </div>

        <div class="votosEnActasContabilizadas">
          <p class="titulo">
            Estadística Municipal 
          </p>
        </div>

        

        <estadistica-entidad />
        <lista-nominal />
        <porcentaje-participacion />
      </div>

      <detalle-eleccion :resumen="resumenDistrito" :votos="votosXPartidoXDistrito" :esXDistrito="true" :key="random"/>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/components/Movil/layouts/Layout'
import VotoPorPartido from '../../componentes/VotoPorPartido.vue'
import ResumenVotacion from '@/components/Movil/componentes/ResumenVotacion'
import DetalleEleccion from '@/components/DetalleEleccionPartidosPoliticos.vue'
import ProgresoVertical from '@/components/Movil/componentes/ProgresoVertical'
import obtenerColoresDeAvatar from '@/utils/obtenerColoresDeAvatar'
import EstadisticaEntidad from '@/components/EstadisticaEntidad.vue';
import ListaNominal from '@/components/ListaNominal.vue';
import PorcentajeParticipacion from '@/components/PorcentajeParticipacion.vue';
import ModalBaseMovil from '@/components/Movil/componentes/ModalBase.vue';

export default {
  name: 'DetalleDelDistritoMovil',
  components: {
    Layout,
    VotoPorPartido,
    ResumenVotacion,
    DetalleEleccion,
    ProgresoVertical,
    EstadisticaEntidad,
    ListaNominal,
    PorcentajeParticipacion,
  },
  data() {
    return {
      random:0
    }
  },
  computed: {
    distrito_nombre() {
      return this.$store.state.elementosGenerales.datos_distritos.find(
        d => d.id == this.distrito
      ).descripcion;
    },
    detalle () {
      /**
       * Aqui debemos de llamar al distrito correspondiente acorde a las variables "Entidad" y "Distrito",
       * por lo pronto quedará estático con lo que tenemos en el JSON que nos dio el INE
       */

      return this.$store.state.distritos.uno
    },
    titulo () {
      if (this.$route.params.eleccion === 'G') { return 'Gubernatura' } else if (this.$route.params.eleccion === 'A') { return 'Ayuntamiento' } else { return 'Diputaciones' }
    },
    eleccion () {
      return this.$route.params.eleccion
    },
    distrito () {
      return this.$route.params.distrito
    },
    ayuntamiento () {
      return this.$store.state.elementosGenerales.datos_municipios[this.distrito - 1]
    },
    cargaTerminada () {
      return this.$store.state.cargaTerminada
    },
    colores () {
      return obtenerColoresDeAvatar(this)
    },
    colorEsperadas () {
      if (this.$store.state.esOscuro) {
        return 'fd2a71'
      }
      return 'd2127e'
    },
    colorCapturadas () {
      if (this.$store.state.esOscuro) {
        return 'fe9db0'
      }
      return '79144c'
    },
    colorContabilizadas () {
      if (this.$store.state.esOscuro) {
        return 'fec5cd'
      }
      return 'efb5d3'
    },
    votosXPartidoXDistrito(){
      if(this.eleccion == "G"){
        return this.$store.state.gVotosXPartidosNivelDistrito
                .distritos[this.distrito - 1]
      }else if(this.eleccion == "D"){
        return this.$store.state.dDistribucionVotosXPPNivelDistrito
                .distritos[this.distrito - 1]
      }else if(this.eleccion == "A"){
        return this.$store.state.aDistribucionVotosXPPNivelDistrito
                .municipios[this.distrito - 1]
      }
    },
    resumenDistrito(){
      if(this.eleccion == "G"){
        return this.$store.state.gResumenNivelDistrito.distritos[this.$route.params.distrito-1]
      }else if(this.eleccion == "D"){
        return this.$store.state.dResumenNivelDistritoConRP.distritos[this.$route.params.distrito-1]
      }else if(this.eleccion == "A"){
        return this.$store.state.aResumenNivelEntidadMunicipio.municipios[this.$route.params.distrito-1]
      }
    },
    estadisticas(){
      if(this.eleccion == "G"){
        return this.$store.state.gEstadisticasNivelDistrito.distritos[this.$route.params.distrito-1]
      }else if(this.eleccion == "D"){
        return this.$store.state.dEstadisticasNivelDistrito.distritos[this.$route.params.distrito-1]
      }else if(this.eleccion == "A"){
        return this.$store.state.aEstadisticasNivelDistrito.municipios[this.$route.params.distrito-1]
      }
    },
    distribucionXPP(){
       if(this.eleccion == "G"){
        return this.$store.state.gDistribucionVotosXPPNivelDistrito.distritos[this.$route.params.distrito-1]
      }else if(this.eleccion == "D"){
        return this.$store.state.dDistribucionVotosXPPNivelDistrito.distritos[this.$route.params.distrito-1]
      }else if(this.eleccion == "A"){
        return this.$store.state.aDistribucionVotosXPPNivelDistrito.municipios[this.$route.params.distrito-1]
      }
    },
    listaSecciones(){
      if(this.eleccion != 'A'){
         return this.$store.state.elementosGenerales.datos_secciones
         .distritos[this.distrito - 1]
         .datos_secciones
      }else{
        return this.$store.state.elementosGenerales.
        datos_secciones_por_municipio[this.distrito - 1]
        .datos_secciones
      }
    }
  },
  methods: {
    actualizarDetalleEleccion() {
        this.$forceUpdate();
        this.random = Math.random();
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-link {
  border-radius: 15px;
  background-color: #C791A8;
  border-color: transparent;
}
</style>
