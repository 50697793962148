<template>
  <div class="vista-escritorio">
    <Cabecera/>
    <Cuerpo>
      <div class="votos">
        <div class="votos-cabecera">
          <div class="titulos">
            <h2>{{ eleccion }} - <span>
              <strong v-if="this.$route.params.eleccion != 'A'">Detalle del Distrito</strong>
              <strong v-else>Detalle por Municipio</strong>
              - Votos por Partido Político y Candidatura Independiente </span></h2>
            <h4>
              <strong>
                <router-link :to="`/${this.$route.params.eleccion}/ENT/VPP`" class="estado-volver">
                  Baja California Sur
                </router-link>
              </strong> /
              <span v-if="this.$route.params.eleccion !== 'A'">Distrito {{distrito}}. {{ distrito_nombre }}</span>
              <span v-else>
                Municipio {{this.distrito}}. {{ ayuntamiento.municipio_descripcion }}
              </span>
            </h4>
            <p>
              El total de votos calculado y porcentaje que se muestran, se refieren a los votos
              asentados en las Actas PREP hasta el momento. <br />
              Por presentación, los decimales de los porcentajes muestran sólo cuatro dígitos. No
              obstante, al considerar todos los decimales, suman 100%.
            </p>
          

            <div class="row px-3">

            <!-- <div class="col-12 mb-3">
                <button
                class="btn btn-primary float-right boton-verdetalle"
                v-b-modal.DetalleEleccionPartidosPoliticosModal
                >
                Ver detalle
              </button>
            </div> -->
            <!-- <div class="col-8 mb-3 resumen-votacion">
              <h4 class="mb-2">
                Resumen de la votación
              </h4>
              <div class="grilla-suma">
                <div class="acumulados text-center">
                  <p class="font-weight-bold">
                    Votos acumulados
                    <span id="tooltip">
                      <font-awesome-icon icon="info-circle" class="fa-sm fa-info" />
                      <span class="tooltiptext">
                        Es la sumatoria de los votos obtenidos <br />
                        por Partido Político, Coalición <br />
                        o Candidatura Independiente.
                      </span>
                    </span>
                    <br />
                    {{ resumenDistrito.total_votos_acumulados_numero }} <br />
                    <span class="porcentaje">
                      {{ resumenDistrito.total_votos_acumulados_porcentaje }}%
                    </span>
                  </p>
                </div>
                <div class="icono-suma">
                  <font-awesome-icon icon="plus" class="fa-2x" />
                </div>
                <div class="no-registradas text-center">
                  <p class="font-weight-bold">
                    Candidaturas no registradas <br />
                    {{ resumenDistrito.total_votos_candidatura_noregistrada_numero }} <br />
                    <span class="porcentaje">
                      {{ resumenDistrito.total_votos_candidatura_noregistrada_porcentaje }}%
                    </span>
                  </p>
                </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="plus" class="fa-2x" />
                  </div>
                  <div class="nulos text-center">
                    <p class="font-weight-bold">
                      Nulos <br />
                      {{ resumenDistrito.total_votos_nulos_numero }} <br />
                      <span class="porcentaje">
                        {{ resumenDistrito.total_votos_nulos_porcentaje }}%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="equals" class="fa-2x" />
                  </div>
                  <div class="total text-right">
                    <p class="font-weight-bold">
                      Total de votos <br />
                      <big>{{ resumenDistrito.total_votos_general_numero }}</big> <br />
                      <span class="porcentaje">
                        {{ resumenDistrito.total_votos_general_porcentaje }}%
                      </span>
                    </p>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <votos-por-partido-politico-tabla/>
      </div>

      <div class="votos simulacro">
        <div class="votos-cabecera">
          <div class="titulos">
            <h4>
              Distribución de votos por Partido Político
              y Candidaturas Independientes a
              nivel {{ eleccion == "Ayuntamientos" ? "Municipio" : "Distrito"}}
            </h4>

            <p>
              La tabla muestra el desglose de votos que cada Partido Político o
              Candidatura Independiente presenta en el
              {{ eleccion == "Ayuntamientos" ? "Municipio" : "Distrito" }},
              indicando los votos recibidos de manera individual y,
              en su caso, los votos recibidos vía Coalición. <br />
              <a href="#/centro-de-ayuda/prep/conoce/que-es" target="__blank" rel="noopener noreferrer" v-b-modal.ConocePorcentajeParticipacionCiudadana>
                <u>
                  Conoce cómo se suman los votos para Candidaturas de
                  Coalición de acuerdo a la legislatura vigente.
                </u>
              </a>
            </p>


            <div class="table-responsive my-4">
              <table class="table table-striped">
                <thead>
                  <tr class="text-center">
                    <th>
                      Partidos Políticos y <br />
                      Candidaturas Independientes
                    </th>
                    <th>
                      Votos para Partidos Políticos <br />
                      y Candidatura Independiente
                    </th>
                    <th>
                      Votos obtenidos vía <br>

                       Coalición
                    </th>
                    <th>
                      Total de votos
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(partido, index) in distribucionXPP.datos_partidos"
                    :key="index"
                    class="text-center"
                  >
                    <td>
                      <img
                        :src="partido.partido_logo"
                        :alt="partido.partido_nombre"
                        class="w-25 d-block mx-auto"
                      >
                    </td>
                    <td>
                      {{ partido.partido_votos_via_individual_numero }}
                      <font-awesome-icon icon="plus-circle"/>
                    </td>
                    <td>
                      {{
                        partido.partido_votos_via_coalicion_numero
                      }}
                      <font-awesome-icon icon="equals"  />
                    </td>
                    <td>
                      {{ partido.partido_votos_total_numero }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>


      <!-- <div class="votos">
        <div class="votos-cabecera">
          <div class="titulos">
            <h3>
              Estadística
              <span v-if="eleccion !== 'Ayuntamientos'">Distrito {{ distrito }}. {{ distrito_nombre }}</span>
              <span v-else>Municipio {{ distrito }}. {{ ayuntamiento.municipio_descripcion }}</span>
            </h3>

            <EstadisticasInternas :detalle="estadisticas" />
          </div>
        </div>
      </div> -->

      <!-- <div class="voto-anticipado fondo d-flex justify-content-center">
         <div class="votos-cabecera">
           <div >

              <div class="mb-4 ml-4 mt-4">
                <img src="/img/votoA.png" height="100px">
              </div>

              <div class=" mb-3">
                <h4>Voto Anticipado</h4>
              </div>


             <router-link :to="{ name: 'VotoAnticipado' }">
                <button class="boton-rosa mb-3 ml-3">Ver Resultados</button>
            </router-link>

           </div>
         </div>
      </div> -->

      <div class="voto-anticipadoF2 d-flex justify-content-center fondo">
        <div>
          <div class="votos-cabecera ">
           <div class="" >
              <div class="mb-4 ml-4 mt-5">
                <img src="/img/votoA.png" height="100px">
              </div>
              <div class=" ml-2 mb-3">
                <h4>Voto Anticipado</h4>
              </div>

              <div v-if="eleccion =='Diputaciones' ">                  
                <div v-if="listaDistritos !='8' && listaDistritos !='9' && listaDistritos !='10' && listaDistritos !='11' && listaDistritos !='13'" >
                    <router-link :to="{ name: 'VotoAnticipado' }" >
                      <button  class="boton-rosa mb-3 ml-3">Ver Resultados </button>
                    </router-link>
                    <br>
                </div>
                  <div v-else class=" ml-3 mb-3" >
                    <h6>Esta Alternativa de Votación
                      <br>
                      no opera en este Distrito.</h6>
                  </div>
              </div>

              <div v-else>
                  <div v-if="ayuntamiento.municipio_id !='1' && ayuntamiento.municipio_id !='5' " >
                      <router-link :to="{ name: 'VotoAnticipado' }" >
                        <button  class="boton-rosa mb-3 ml-3">Ver Resultados </button>
                      </router-link>
                      <br>
                  </div>                
                  <div v-else class=" ml-3 mb-3" >
                    <h6>Esta Alternativa de Votación
                      <br>
                      no opera en este Municipio.</h6>
                  </div>      
              </div>
              

                

           </div>
         </div>
        </div>

      </div>

      <div class="titulo ">
                    <h3>Estadística Entidad</h3>
      </div>
      <estadistica-entidad/>
      <lista-nominal/>
      <porcentaje-participacion />

      <div class="text-center">
        <p>
          Los resultados presentados tienen un carácter informativo y no son definitivos,
          por tanto, no tienen efectos jurídicos.
        </p>
      </div>
    </Cuerpo>
    <compartir />
    <PieDePagina />
    <detalle-eleccion :resumen="resumenDistrito" :votos="votos" :esXDistrito="false" />
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from '@vue/composition-api';
import Cabecera from '@/components/Escritorio/componentes/Cabecera';
import Cuerpo from '@/components/Escritorio/componentes/Cuerpo';
import EstadisticasInternas from '@/components/Escritorio/componentes/EstadisticasInternas.vue';
import DetalleEleccion from '@/components/DetalleEleccionPartidosPoliticos.vue';
import Compartir from '@/components/Compartir.vue';
import PieDePagina from '@/components/Escritorio/componentes/PieDePagina.vue';
import VotosPorPartidoPoliticoTabla from '@/components/Escritorio/vistas/VotosPorPartidoPoliticoTabla.vue';
import EstadisticaEntidad from '@/components/EstadisticaEntidad.vue';
import ListaNominal from '@/components/ListaNominal.vue';
import PorcentajeParticipacion from '@/components/PorcentajeParticipacion.vue';

export default defineComponent({
  name: 'DetalleDelDistritoVPPEscritorio',
  components: {
    Cabecera,
    Cuerpo,
    VotosPorPartidoPoliticoTabla,
    EstadisticasInternas,
    ListaNominal,
    PorcentajeParticipacion,
    EstadisticaEntidad,
    DetalleEleccion,
    Compartir,
    PieDePagina,
  },
  data() {
    return {
      random: 0,
      votos: {
        partidos: [],
      },
    };
  },
  computed: {
    distrito_nombre() {
      return this.$store.state.elementosGenerales.datos_distritos.find(d => d.id == this.distrito).descripcion;
    },
    eleccion() {
      if (this.$route.params.eleccion === 'G') {
        return 'Gubernatura';
      } if (this.$route.params.eleccion === 'D') {
        return 'Diputaciones';
      } if (this.$route.params.eleccion === 'A') {
        return 'Ayuntamientos';
      }

      return '';
    },
    ayuntamiento() {
      return this.$store.state.elementosGenerales.datos_municipios[this.distrito - 1];
    },
    distrito() {
      return this.$route.params.distrito;
    },
    votosXPartidoXDistrito() {
      if (this.eleccion === 'Gubernatura') {
        return this.$store.state.gVotosXPartidoPoliticoVistaTargetasNivelDistrito
          .distritos[this.distrito - 1];
      } if (this.eleccion === 'Diputaciones') {
        return this.$store.state.dVotosXPartidoPoliticoVistaTargetasNivelDistrito
          .distritos[this.distrito - 1];
      }
      return this.$store.state.aVotosXPartidoPoliticoVistaTargetasNivelMunicipio
        .municipios[this.distrito - 1];
    },
    resumenDistrito() {
      if (this.eleccion === 'Gubernatura') {
        return this.$store.state.gResumenNivelDistrito.distritos[this.$route.params.distrito - 1];
      } if (this.eleccion === 'Diputaciones') {
        return this.$store.state.dResumenNivelDistrito.distritos[this.$route.params.distrito - 1];
      }
      return this.$store.state.aResumenNivelDistrito.distritos[this.$route.params.distrito - 1];
    },
    estadisticas() {
      if (this.eleccion === 'Gubernatura') {
        return this.$store.state.gEstadisticasNivelDistrito.distritos[
          this.$route.params.distrito - 1
        ];
      } if (this.eleccion === 'Diputaciones') {
        return this.$store.state.dEstadisticasNivelDistrito.distritos[
          this.$route.params.distrito - 1
        ];
      }
      return this.$store.state.aEstadisticasNivelDistrito.municipios[
        this.$route.params.distrito - 1
      ];
    },
    distribucionXPP() {
      if (this.eleccion === 'Ayuntamientos') {
        return this.$store.state.aDistribucionVotosXPPNivelDistrito.municipios[
          this.$route.params.distrito - 1
        ];
      } if (this.eleccion === 'Diputaciones') {
        return this.$store.state.dDistribucionVotosXPPNivelDistrito.distritos[
          this.$route.params.distrito - 1
        ];
      }
      return this.$store.state.gDistribucionVotosXPPNivelDistrito.distritos[
        this.$route.params.distrito - 1
      ];
    },
    tresSeleccionados() {
      let seleccionados = 0;

      this.votos.partidos.forEach((candidatura) => {
        if (candidatura.seleccionado) {
          // eslint-disable-next-line no-plusplus
          seleccionados++;
        }

        return null;
      });

      return seleccionados === 3;
    },
  },
  created() {
    this.setearVotos();
  },
  methods: {
    /* onCheckboxChange(e) {
      this.$forceUpdate();
      this.random = Math.random();
    }, */
    setearVotos() {
      this.votos = this.votosXPartidoXDistrito;
      // const niveles = {
      //   G: 'gVotosXPartidosNivelDistrito',
      //   A: 'aVotosXPartidosNivelDistrito',
      //   D: 'dVotosXPartidosNivelDistrito',
      // };

      // const { distrito, eleccion } = this.$route.params;

      // const estado = this.$store.state[
      //   niveles[eleccion]
      // ];

      // if (eleccion === 'A') {
      //   this.votos = estado?.municipios[distrito - 1];
      // } else {
      //   this.votos = estado?.distritos[distrito - 1];
      // }
    },
  },
  setup() {
    const constanteScroll = 150;
    const maximoLogitudScroll = ref(0);
    const horizontalScrollValue = ref(0);

    const scrollDerecha = () => {
      const row = document.getElementById('row-flex');
      if (horizontalScrollValue.value < (row.scrollWidth)) {
        horizontalScrollValue.value += constanteScroll;
      }
      row.scrollTo(horizontalScrollValue.value, 0);
    };

    const scrollIzquierda = () => {
      const row = document.getElementById('row-flex');
      if (horizontalScrollValue.value > 0) {
        horizontalScrollValue.value -= constanteScroll;
      }
      row.scrollTo(horizontalScrollValue.value, 0);
    };

    onMounted(() => {
      const row = document.getElementById('row-flex');
      maximoLogitudScroll.value = row.scrollWidth - row.offsetWidth;
    });

    return {
      scrollDerecha,
      scrollIzquierda,
      horizontalScrollValue,
      maximoLogitudScroll,
    };
  },
});
</script>

<style lang="scss" scoped>

.titulo{
  h3{
    font-size: 26px;
    color: black;
    font-weight: bold;
    border-left: 15px solid #582D73;
    padding-left: 10px;
  }
}
.resumen-voto-candidatura {
  min-height: 100%;
  display: grid;
  grid-template-rows: 1fr;
  /* padding: 3px; */
  border: 1px solid #582D73;
  //height: 25vh;
  width: 190px;
  /* max-width: 150px; */
  .grilla-datos-candidatura {
    margin: 10px;
    .foto > svg {
      max-width: 50px;
      margin: 0 auto;
      display: block;
      margin-bottom: 10px;
    }
    .nombre{
      height: 70px;
    }
  }
  .grilla-resumen {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-end;
    gap: 15px;
    padding: 3px 5px;
    margin-top:10px;
  }
}

.ganador {
  border-color: #D2127E;
  border-width: 3px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;

  .total {
    p > span {
      color: #582D73;
    }
  }
}

.boton-seleccionar {
  width: 100%;
  display: block;
  text-align: center;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: #582D73;
  border-color: transparent;

  label {
    border: none;
    width: 100%;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}

.seleccionado {
  background-color: lighten(#582D73, 10%);
}

.resumen-votacion {
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 15px;

  .grilla-suma {
    display: grid;
    grid-template-columns: 1fr 20px 1fr 20px 1fr 20px 1fr;
    /* max-width: 900px; */
    align-items: center;
    gap: 30px;

    .acumulados, .nulos, .no-registradas, .total {
      p {
        color: #582D73;
      }
    }

    .total {
      big {
        font-size: 2rem;
      }
      big, .porcentaje  {
        // color: #D2127E;
        color: #582D73;
      }
    }
  }
}

.table-responsive {
  tbody {
    tr {
      td {
        vertical-align: middle;
        img {
          max-width: 50px;
        }
        svg{
          float: right;
          &[data-icon="equals"]{
              background-color: black;
              color: white;
              padding: 5px;
              border-radius: 50px;
              width: 20px;
              font-size: 20px;
          }
        }
      }
      .votos {
        text-align: center;
        p {
          font-size: 2rem !important;
          font-weight: bold !important;
        }
      }
    }
  }
}

.boton-verdetalle {
  background-color: #582D73;
  border-color: transparent;
}

.rowFlex {
  display: flex;
  flex-flow: nowrap;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 1rem;
  scroll-behavior: smooth;
}

.contenedor-padre {
  display: grid;
  grid-template-columns: 30px 1fr 30px;
  align-items: center;
  gap: 15px;

  .btn-fijo {
    background: transparent;
    border: none;
    color: #707070;
    z-index: 1;
    transition: all ease .2s;

    &:disabled {
      cursor: not-allowed;
      opacity: 50%;
    }
  }
}

</style>
